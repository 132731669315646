:root {
    --color-primary-5: #000D35;
    --color-primary-10: #00174C;
    --color-primary-20: #002979;
    --color-primary-30: #18409D;
    --color-primary-40: #3759B6;
    --color-primary-50: #5272D1;
    --color-primary-60: #6D8CED;
    --color-primary-70: #8DA8FF;
    // --color-primary-75: #B4C5FF;
    --color-primary-80: #B4C5FF;
    --color-primary-90: #DBE1FF;
    --color-primary-95: #EFF0FF;
  
    --color-primary: #1D43A0;

    --tab-text-color: var(--color-primary);
    --tab-icon-color: var(--color-primary);
    --tab-section-color: #D2E4FF;

    --toolbar-bg-color: #E9F1FF;
    --color-button-hover: var(--color-primary-40);

    --color-moment-night: var(--color-primary-20);
    --color-moment-secondary-night: var(--color-primary-50);
    --color-moment-tertiary-night: var(--color-primary-60);
    --color-moment-cuaternary-night: var(--color-primary-70);
}